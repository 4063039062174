<template>
  <div>
    <v-card tile flat>
      <v-toolbar color="secondary" dense flat dark>
        <v-toolbar-title>
          <span>Detalle de reparaciones</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Próximo folio</v-list-item-subtitle>
            <v-list-item-title>{{
              invoiceIdLocal || "Sin definir"
            }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="showDialog = true">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
    </v-card>

    <invoice-setter
      :show="showDialog"
      :invoiceId="invoiceIdLocal"
      @cancel="showDialog = false"
      @invoiceIdChanged="invoiceIdChanged"
    ></invoice-setter>
  </div>
</template>

<script>
import invoiceSetter from "./invoiceIdSetter.dialog.vue";

export default {
  props: {
    invoiceId: { type: String, default: null },
  },

  components: {
    "invoice-setter": invoiceSetter,
  },

  data() {
    return {
      invoiceIdLocal: this.invoiceId,
      showDialog: false,
    };
  },

  methods: {
    invoiceIdChanged(invoiceId) {
      this.invoiceIdLocal = invoiceId;
      this.showDialog = false;
    },
  },
};
</script>
