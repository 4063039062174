<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-divider></v-divider>
        <v-subheader>Cliente</v-subheader>
      </v-col>
      <v-col cols="12" md="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Nombre</v-list-item-subtitle>
            <v-list-item-title>{{
              customerData.existingCustomer
                ? `${customerData.existingCustomer.name} ${customerData.existingCustomer.lastName}`
                : customerData.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6" v-if="customerIdentification">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Cédula</v-list-item-subtitle>
            <v-list-item-title>{{ customerIdentification }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6" v-if="customerPhoneNumber">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Número telefónico</v-list-item-subtitle>
            <v-list-item-title>{{ customerPhoneNumber }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6" v-if="customerEmail">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Correo electrónico</v-list-item-subtitle>
            <v-list-item-title>{{ customerEmail }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6" v-if="customerAdress">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Dirección</v-list-item-subtitle>
            <v-list-item-title>{{ customerAdress }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "customerSection",

  props: {
    customerData: { type: Object, required: true },
  },

  computed: {
    customerIdentification() {
      if (this.customerData.existingCustomer)
        if (this.customerData.existingCustomer.identification)
          return this.customerData.existingCustomer.identification;
      if (this.customerData.identification)
        return this.customerData.identification;
      return null;
    },

    customerEmail() {
      if (this.customerData.existingCustomer)
        if (this.customerData.existingCustomer.email)
          return this.customerData.existingCustomer.email;
      if (this.customerData.email) return this.customerData.email;
      return null;
    },

    customerPhoneNumber() {
      if (this.customerData.existingCustomer)
        if (this.customerData.existingCustomer.phoneNumber)
          return this.customerData.existingCustomer.phoneNumber;
      if (this.customerData.phoneNumber) return this.customerData.phoneNumber;
      return null;
    },

    customerAdress() {
      if (this.customerData.existingCustomer)
        if (this.customerData.existingCustomer.adress)
          return this.customerData.existingCustomer.adress;
      if (this.customerData.adress) return this.customerData.adress;
      return null;
    },
  },
};
</script>
