<template>
  <div style="height: 100%">
    <v-card outlined tile style="height: 100%">
      <v-card-title>
        <span>{{ customerLocal.name }}</span>
        <v-spacer></v-spacer>
        <v-menu left>
          <template #activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showModification = true">
              <v-list-item-icon class="mr-2">
                <v-icon small>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span>Modificar cliente</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-subtitle>
        <v-subheader class="ma-0 pa-0">{{ customer.lastName }}</v-subheader>
      </v-card-subtitle>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-subtitle>Identificación:</v-list-item-subtitle>
                <v-list-item-title>{{
                  customerLocal.identification
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-subtitle
                  >Porcentaje de descuento:</v-list-item-subtitle
                >
                <v-list-item-title>
                  <v-chip label>{{ customerLocal.discountPercent }}%</v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="customerLocal.email" cols="12" md="6">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-subtitle>Correo electrónico:</v-list-item-subtitle>
                <v-list-item-title>{{ customerLocal.email }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="customerLocal.phoneNumber" cols="12" md="6">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-subtitle>Número telefónico:</v-list-item-subtitle>
                <v-list-item-title>{{
                  customerLocal.phoneNumber
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="customerLocal.adress" cols="12" md="6">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-subtitle>Dirección:</v-list-item-subtitle>
                <v-list-item-title>{{
                  customerLocal.adress
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <customer-modification
      v-if="showModification"
      :show="showModification"
      :customer="customerLocal"
      @cancel="showModification = false"
      @customerSaved="customerSaved"
    ></customer-modification>
  </div>
</template>

<script>
export default {
  props: {
    customer: { type: Object, required: true },
  },

  components: {
    "customer-modification": () => import("./customerCreation.vue"),
  },

  data() {
    return {
      showModification: false,
      customerLocal: this.customer,
    };
  },

  methods: {
    customerSaved(customer) {
      this.customerLocal = customer;
      this.showModification = false;
    },
  },
};
</script>
