<template>
  <div class="py-2">
    <v-row
      :no-gutters="!$vuetify.breakpoint.mdAndUp"
      :dense="$vuetify.breakpoint.mdAndUp"
    >
      <v-col cols="12">
        <v-alert
          v-if="existingCustomer && existingCustomer.discountPercent"
          type="info"
        >
          <span
            >{{ existingCustomer.name }} tiene un descuento del
            {{ existingCustomer.discountPercent }}% sobre el total del costo de
            la reparación</span
          >
        </v-alert></v-col
      >
      <v-col cols="12" v-if="existingCustomer">
        <v-alert type="warning" dense text>
          <span
            >Al seleccionar un cliente existente, este dato no se puede
            modificar. <strong>Selecciona de forma correcta.</strong></span
          >
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="existingCustomer"
          :items="customers"
          outlined
          dense
          label="Cliente registrado"
          :filter="filterAction"
          color="secondary"
          item-color="secondary"
          :readonly="
            (!!currentCustomer && !!existingCustomer) ||
            (!!currentCustomer && !existingCustomer)
          "
          :hint="
            !!currentCustomer && !existingCustomer
              ? 'Campo inhabilitado debido a que en la creación de servicio no se escogió un cliente existente'
              : ''
          "
          :persistent-hint="!!currentCustomer && !existingCustomer"
        >
          <template #selection="{ item }">
            <span>{{ item.name }} {{ item.lastName }}</span>
          </template>
          <template #item="{ item }">
            <span>{{ item.name }} {{ item.lastName }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="name"
          label="Nombre del cliente"
          outlined
          dense
          color="secondary"
          :error-messages="errors.name"
          :disabled="!!existingCustomer"
          @input="validateName()"
          @blur="validateName()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="identification"
          label="Cédula"
          outlined
          dense
          color="secondary"
          :error-messages="errors.identification"
          :disabled="!!existingCustomer"
          @input="validateIdentification()"
          @blur="validateIdentification()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="phoneNumber"
          label="Número telefónico (opcional)"
          outlined
          dense
          color="secondary"
          :error-messages="errors.phoneNumber"
          :disabled="!!existingCustomer"
          @input="validatePhoneNumber()"
          @blur="validatePhoneNumber()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="email"
          label="Correo electrónico (opcional)"
          outlined
          dense
          color="secondary"
          :error-messages="errors.email"
          :disabled="!!existingCustomer"
          @input="validateEmail()"
          @blur="validateEmail()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="adress"
          label="Dirección (opcional)"
          :disabled="!!existingCustomer"
          outlined
          dense
          color="secondary"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="d-flex">
          <v-btn
            color="secondary"
            outlined
            @click="$emit('cancel')"
            class="mr-2"
          >
            <v-icon small class="mr-2">mdi-arrow-left</v-icon>
            {{ $vuetify.breakpoint.mdAndUp ? "Paso anterior" : null }}</v-btn
          >
          <v-btn
            color="primary"
            dark
            :disabled="!isFormValid"
            :loading="loading"
            @click="validateStep"
          >
            <span>{{ $vuetify.breakpoint.mdAndUp ? "Siguiente" : null }}</span>
            <v-icon small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import serverRequestMixin from "@/mixins/serverRequest.mixin.js";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import { _ } from "vue-underscore";

export default {
  name: "customerDialogStep",

  props: {
    currentCustomer: { type: Object, default: null },
  },

  mixins: [validationMixin, serverRequestMixin],

  computed: {
    isFormValid() {
      if (this.errors.name.length) return false;
      if (this.errors.identification.length) return false;
      if (this.errors.phoneNumber.length) return false;
      if (this.errors.email.length) return false;
      return true;
    },
  },

  watch: {
    currentCustomer() {
      this.setCurrentCustumer();
    },

    existingCustomer(value) {
      if (value) {
        this.errors.name = [];
        this.errors.identification = [];
        this.errors.phoneNumber = [];
        this.errors.email = [];
      }
    },
  },

  data: () => ({
    loading: false,
    error: "",
    name: "",
    identification: "",
    phoneNumber: "",
    email: "",
    adress: "",
    errors: {
      name: [],
      identification: [],
      phoneNumber: [],
      email: [],
    },
    customers: [],
    existingCustomer: null,
  }),

  validations: {
    name: { required },
    identification: { required },
    phoneNumber: {
      numeric,
      minLength: minLength(6),
      maxLength: maxLength(10),
    },
    email: { email },
  },

  async mounted() {
    await this.getCustomers();
    if (this.currentCustomer) {
      this.setCurrentCustumer();
    }
  },

  methods: {
    validateName() {
      const errors = [];
      this.$v.name.$touch();
      if (!this.$v.name.required && !this.existingCustomer)
        errors.push("El nombre es requerido");
      this.errors.name = errors;
    },

    validateIdentification() {
      this.$v.identification.$touch();
      this.errors.identification = [
        ...(this.$v.identification.required
          ? []
          : ["La cédula del cliente es requerida."]),
      ];
    },

    validatePhoneNumber() {
      const errors = [];
      this.$v.phoneNumber.$touch();
      !this.$v.phoneNumber.numeric &&
        errors.push("Número telefónico no válido");
      !this.$v.phoneNumber.minLength &&
        errors.push("El número telefónico debe tener entre 6 y 10 dígitos");
      !this.$v.phoneNumber.maxLength &&
        errors.push("El número telefónico debe tener entre 6 y 10 dígitos");
      this.errors.phoneNumber = errors;
    },

    validateEmail() {
      const errors = [];
      this.$v.email.$touch();
      !this.$v.email.email && errors.push("Correo electrónico no válido");
      this.errors.email = errors;
    },

    validateStep() {
      if (!this.existingCustomer) {
        this.validateName();
        this.validateIdentification();
        this.validatePhoneNumber();
        this.validateEmail();
        if (!this.isFormValid) return;
      }

      let emitData = {};
      if (this.existingCustomer) {
        emitData.existingCustomer = this.existingCustomer;
      } else {
        emitData = {
          name: this.name,
          identification: this.identification,
        };
        if (this.phoneNumber) emitData.phoneNumber = this.phoneNumber;
        if (this.email) emitData.email = this.email;
        if (this.adress) emitData.adress = this.adress;
      }

      this.$emit("stepValid", emitData);
    },

    setCurrentCustumer() {
      if (this.currentCustomer) {
        const keys = _.keys(this.currentCustomer);
        keys.forEach((key) => {
          if (
            this[key] ||
            this[key] === null ||
            this[key] === "" ||
            this[key] === false
          )
            this[key] = this.currentCustomer[key];
        });
      }
    },

    async getCustomers() {
      this.loading = true;
      this.error = "";

      try {
        const serverResponse = await this.getRequest("/customers");
        this.loading = false;

        this.customers = serverResponse.customers;
      } catch (error) {
        this.loading = false;
        if (error.data) this.error = error.data.message;
        else {
          console.error(error);
          this.error = "Error inesperado, favor de contactar con soporte";
        }
      }
    },

    filterAction(item, queryText) {
      const fullName = `${item.name} ${item.lastName}`;
      return (
        fullName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
};
</script>
