var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-card-title',[_c('span',[_vm._v("Pago No. "+_vm._s(_vm.commissionNumber))]),_c('v-btn',{attrs:{"color":"green darken-2","large":"","outlined":"","block":""},on:{"click":function($event){return _vm.$emit('submit', { payrollAmount: _vm.payrollAmount })}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-arrow-up")]),_c('span',[_vm._v("Pagar "+_vm._s(_vm.currencyFormat(_vm.totalAmount)))]),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-arrow-up")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"color":"accent","outlined":""}},[_c('v-card-title',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.repairs.length)+" Servicios ")]),_c('v-card-subtitle',[_vm._v("Entregados en el rango seleccionado")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"color":"accent","outlined":""}},[_c('v-card-title',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.commissionsTotal))+" ")]),_c('v-card-subtitle',[_vm._v("En total de comisiones")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"filled":"","color":"secondary","label":"Nómina (opcional)","type":"number","hint":_vm.payrollAmount
                  ? _vm.currencyFormat(_vm.payrollAmount)
                  : 'La cantidad ingresada aquí se agregará al registro de nóminas',"persistent-hint":"","prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.payrollAmount),callback:function ($$v) {_vm.payrollAmount=$$v},expression:"payrollAmount"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v("Lista de servicios")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.repairs,"item-key":"_id","dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","no-data-text":"Sin servicios"},scopedSlots:_vm._u([{key:"item.device.trademark",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.device.trademark)+" "+_vm._s(item.device.model)+" ")])]}},{key:"item.admissionDate",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getShortDate(item.admissionDate))+" ")])]}},{key:"item.lastStatusUpdate",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getShortDate(item.lastStatusUpdate))+" ")])]}},{key:"item.payment.totalCost",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormat(item.payment.totalCost))+" ")])]}},{key:"item.commission.commissionPercent",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"label":"","color":item.commission.commissionPercent ? 'secondary' : null}},[(item.commission.commissionPercent)?_c('span',[_vm._v(_vm._s(item.commission.commissionPercent)+" %")]):_c('span',[_vm._v("N/A")])])],1)]}},{key:"item.payment.prePayment",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.getCommissionAmount(item)))+" ")])]}},{key:"item.customer.name",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.customer.existingCustomer ? item.customer.existingCustomer.name : item.customer.name)+" ")])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }