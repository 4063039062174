<template>
  <div>
    <customers-list></customers-list>
  </div>
</template>

<script>
import customersList from "./customersList.vue";

export default {
  components: {
    "customers-list": customersList,
  },
};
</script>
