<template>
  <div>
    <v-card tile flat>
      <v-card-title>
        <span>Pago de comisiones</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$router.push('/panel/comisiones/nueva')">
          <v-icon small>mdi-plus</v-icon>
          <span v-if="!isMobile" class="ml-2">Nuevo pago</span>
        </v-btn>
      </v-card-title>
      <v-card-text
        >En este apartado puedes registrar y visualizar los pagos a cada usuario
        que tiene una comisión. Para configurar el porcentaje de comisiones de
        cada persona visita el apartado
        <v-btn
          small
          text
          color="primary"
          @click="$router.push('/panel/usuarios')"
          >usuarios</v-btn
        ></v-card-text
      >
    </v-card>

    <div class="mt-3">
      <commissions-list></commissions-list>
    </div>
  </div>
</template>

<script>
import commissionsPaymentsList from "./commissionsPaymentsList.vue";

export default {
  components: {
    "commissions-list": commissionsPaymentsList,
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
