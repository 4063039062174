import serverRequestMixin from "@/mixins/serverRequest.mixin";

export default {
  mixins: [serverRequestMixin],

  methods: {
    async updateUser(user, payload) {
      try {
        const serverResponse = await this.putRequest(
          `users/${user.businessId}/${user._id}`,
          payload
        );
        return serverResponse.userUpdated;
      } catch (error) {
        if (error.status != 500) throw new Error(error.data.message);
        else {
          console.error(error);
          throw new Error(
            "Ocurrió un erro al actualizar el usuario, favor de contactar a soporte."
          );
        }
      }
    },
  },
};
