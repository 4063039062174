<template>
  <div>
    <v-card :loading="loading">
      <v-toolbar color="secondary" dark dense tile flat>
        <v-toolbar-title>
          <span>Listado de clientes</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="showCustomerCreation = true">
          <span v-if="!isMobile" class="mr-2">Agregar cliente</span>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
        <v-row dense>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="search"
              color="secondary"
              outlined
              dense
              label="Buscar cliente"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-iterator
          :items="customers"
          :items-per-page="10"
          no-data-text="Sin clientes para mostrar"
          loading-text="Cargando..."
          no-results-text="Sin resultados"
          :search="search"
          :footer-props="{
            'items-per-page-text': 'Clientes por página',
            'items-per-page-all-text': 'Todos',
            'page-text': `$vuetify.dataFooter.pageText`,
          }"
        >
          <template #default="{ items }">
            <v-row dense>
              <v-col
                v-for="customer in items"
                :key="customer._id"
                cols="12"
                md="6"
                lg="4"
              >
                <customer-item :customer="customer"></customer-item>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>

    <customer-creator
      v-if="showCustomerCreation"
      :show="showCustomerCreation"
      @customerSaved="customerSaved"
      @cancel="showCustomerCreation = false"
    ></customer-creator>
  </div>
</template>

<script>
import serverRequestMixin from "@/mixins/serverRequest.mixin.js";

import customerItem from "./customerItem.vue";

export default {
  mixins: [serverRequestMixin],

  components: {
    "customer-item": customerItem,
    "customer-creator": () => import("./customerCreation.vue"),
  },

  data: () => ({
    loading: false,
    error: "",
    customers: [],
    search: "",
    showCustomerCreation: false,
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  mounted() {
    this.getCustomers();
  },

  methods: {
    async getCustomers() {
      this.loading = true;

      try {
        const serverResponse = await this.getRequest("/customers");
        this.loading = false;

        this.customers = serverResponse.customers;
      } catch (error) {
        this.loading = false;
        if (error.data) this.error = error.data.message;
        else {
          this.error = "Error inesperado, favor de contactar con soporte";
          console.error(error);
        }
      }
    },

    customerSaved(customer) {
      this.customers = [customer, ...this.customers];
      this.showCustomerCreation = false;
    },
  },
};
</script>
