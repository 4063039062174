<template>
  <div>
    <v-card flat tile>
      <v-card-title class="d-flex align-items-center">
        <span>Movimientos económicos</span>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="ml-2"
              >mdi-help-circle</v-icon
            >
          </template>
          Todos los gastos e ingresos de la reparación pueden ser agregados aquí
          para calcular las ganancias
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <span v-if="!movements.length">Sin movimientos agregados</span>
        <div v-else>
          <v-card
            v-for="movement in movementsLocal"
            :key="movement._id"
            flat
            tile
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon :color="isBill(movement) ? 'red' : 'green'">{{
                  isBill(movement) ? "mdi-arrow-down" : "mdi-arrow-up"
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    >{{ movement.description }} -
                    {{ getFullDateWithHour(movement.date) }}</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ currencyFormat(movement.amount) }} -
                  {{ movement.user.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="isRole(0)">
                <v-btn icon @click="openMovementUpdate(movement)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </div>
      </v-card-text>
    </v-card>

    <update-dialog
      v-if="showUpdate"
      :show="showUpdate"
      :movement="movementSelected"
      @cancel="closeMovementUpdate()"
      @movementSaved="movementSaved"
    ></update-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { currencyFormat } from "@/helpers/numbers.helper";
import { getFullDateWithHour } from "@/helpers/date.helper";

export default {
  props: {
    movements: { type: Array, default: () => [] },
  },

  components: {
    "update-dialog": () => import("./addRepairMovement.vue"),
  },

  data() {
    return {
      movementsLocal: this.movements,
      movementSelected: null,
      showUpdate: false,
    };
  },

  watch: {
    movements(value) {
      this.movementsLocal = value;
    },
  },

  computed: {
    ...mapGetters(["isRole"]),
  },

  methods: {
    currencyFormat,
    getFullDateWithHour,

    isBill(movement) {
      return movement.movementType === "bill";
    },

    openMovementUpdate(movement) {
      this.movementSelected = movement;
      this.showUpdate = true;
    },

    closeMovementUpdate() {
      this.showUpdate = false;
      this.movementSelected = null;
    },

    movementSaved(movement) {
      const movementIndex = this.movementsLocal.findIndex(
        (e) => e._id === movement._id
      );
      if (movementIndex >= 0) this.movementsLocal[movementIndex] = movement;
      this.closeMovementUpdate();
    },
  },
};
</script>
