var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3"},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-items-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-format-list-bulleted")]),_c('span',[_vm._v("Listado")]),(!_vm.isMobile)?_c('span',{staticClass:"ml-1"},[_vm._v("de reparaciones")]):_vm._e()],1),_c('v-spacer'),(_vm.isRole(0) || _vm.isRole(1))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#008000","loading":_vm.loadingFile},on:{"click":function($event){return _vm.getRepairsFile()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")]),(!_vm.isMobile)?_c('span',{staticClass:"ml-2"},[_vm._v("Exportar a Excel")]):_vm._e()],1)]}}],null,false,4012219338)},[_c('span',[_vm._v("Se descargarán las reparaciones con los mismos filtros activos")])]):_vm._e()],1),_c('v-card-text',[(_vm.fileError)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.fileError))]):_vm._e(),(_vm.selectedRepairs.length)?_c('v-container',{staticClass:"pt-0"},[_c('v-subheader',{staticClass:"px-0"},[_vm._v("Acciones disponibles:")]),_c('div',{staticClass:"flex"},[(
              _vm.user.role.role === 0 ||
              _vm.user.permissions.filter(function (e) { return e.key === 331; }).length > 0
            )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openManyRepairsStatusDialog()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"secondary"}},[_vm._v("mdi-devices")]),_c('span',{staticClass:"secondary--text"},[_vm._v("Modificar estado")])],1):_vm._e(),_c('v-divider',{attrs:{"vertical":""}}),(
              (_vm.user.role.role === 0 ||
                _vm.user.permissions.filter(function (e) { return e.key === 340; }).length > 0) &&
              _vm.selectedRepairs.length > 1
            )?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.openDeleteManyRepairsDialog()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('span',[_vm._v("Eliminar")])],1):_vm._e()],1)],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.repairs,"expanded":_vm.expanded,"single-expand":"","loading":_vm.loading,"loading-text":"Cargando...","show-select":"","no-data-text":"No hay reparaciones para mostrar","no-results-text":"No se encontraron reparaciones","hide-default-footer":"","item-key":"_id","show-expand":"","disable-sort":"","items-per-page":20},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.invoiceId",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"accent","small":"","elevation":"0","tile":""},on:{"click":function($event){return _vm.$router.push(("/panel/reparaciones/" + (item.invoiceId)))}}},[_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(item.invoiceId))])])]}},{key:"item.status.title",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(
                _vm.repairs.filter(function (e) { return e._id === item._id; })[0].status.key
              ),"dark":"","label":""}},[_vm._v(" "+_vm._s(_vm.repairs.filter(function (e) { return e._id === item._id; })[0].status.title)+" ")])]}},{key:"item.branchOffice.name",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"color":"grey","dark":"","label":""}},[_vm._v(" "+_vm._s(item.branchOffice.name)+" ")])]}},{key:"item.admissionDate",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getShortDateLocal(item.admissionDate)))])]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"elevation-0",attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Motivo de ingreso (falla):")]),_c('v-list-item-title',[_vm._v(_vm._s(item.device.reasonForAdmission))])],1)],1)],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(
              _vm.user.role.role === 0 ||
              _vm.user.permissions.filter(function (e) { return e.key === 331; }).length > 0
            )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEditRepairStatusDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-devices ")])]}}],null,true)},[_c('span',[_vm._v("Modificar estado")])]):_vm._e(),(
              _vm.user.role.role === 0 ||
              _vm.user.permissions.filter(function (e) { return e.key === 330; }).length > 0
            )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openMovementDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-swap-vertical ")])]}}],null,true)},[_c('span',[_vm._v("Agregar movimiento")])]):_vm._e(),(
              _vm.user.role.role === 0 ||
              _vm.user.permissions.filter(function (e) { return e.key === 340; }).length > 0
            )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteRepairStatusDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e()]}}],null,true),model:{value:(_vm.selectedRepairs),callback:function ($$v) {_vm.selectedRepairs=$$v},expression:"selectedRepairs"}})],1)],1),(_vm.showEditRepairStatus)?_c('updateStatusDialog',{attrs:{"show":_vm.showEditRepairStatus,"invoiceId":_vm.invoiceIdToUpdate,"currentStatus":_vm.repairToAction.status.key},on:{"cancel":_vm.closeEditRepairStatusDialog,"repairSaved":_vm.repairSaved,"manyRepairsSaved":_vm.manyRepairsSaved}}):_vm._e(),(_vm.showDeleteRepair)?_c('deleteRepairDialog',{attrs:{"show":_vm.showDeleteRepair,"invoiceId":_vm.invoiceIdToUpdate,"deleteQueryString":_vm.deleteQueryString},on:{"cancel":_vm.closeDeleteRepairStatusDialog,"repairDeleted":_vm.repairDeleted,"manyRepairsDeleted":_vm.manyRepairsDeleted}}):_vm._e(),(_vm.showMovementDialog)?_c('movement-dialog',{attrs:{"show":_vm.showMovementDialog,"invoiceId":_vm.invoiceIdToUpdate},on:{"cancel":function($event){return _vm.closeMovementDialog()},"movementSaved":function($event){return _vm.closeMovementDialog()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }