<template>
  <div>
    <v-row>
      <v-col v-if="error" cols="12">
        <v-alert type="error">{{ error }}</v-alert>
      </v-col>

      <v-col cols="12" :lg="technician ? '9' : '12'">
        <v-card tile flat>
          <v-card-title>
            <span>Nuevo pago de comisión</span>
          </v-card-title>
          <v-card-text>
            <span
              >Para calcular las comisiones de técnicos, selecciona uno y el
              rango de fechas de reparaciones entregadas a los clientes.</span
            >
            <div class="pt-3">
              <commission-filters
                title="Buscar reparaciones"
                :current="$route.query"
                :loading="loading"
                @queryChanged="queryChanged"
              ></commission-filters>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="technician" cols="12" lg="3">
        <user-details :user="technician"></user-details>
      </v-col>

      <v-col v-if="technician" cols="12">
        <payment-details
          :repairs="repairs"
          :commissionPercent="technician.commissionPercent"
          :commissionNumber="commissionNumber"
          @submit="submit"
        ></payment-details>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSuccess" color="success">
      <span>{{ successMessage }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import serverRequestMixin from "@/mixins/serverRequest.mixin";

import commissionPaymentFilters from "./commissionsPaymentFilters.vue";
import commissionUserDetails from "./commissionUserDetails.vue";
import commissionPaymentDetails from "./commissionPaymentDetails.vue";

export default {
  mixins: [serverRequestMixin],

  components: {
    "commission-filters": commissionPaymentFilters,
    "user-details": commissionUserDetails,
    "payment-details": commissionPaymentDetails,
  },

  data: () => ({
    loading: false,
    error: "",
    technician: null,
    repairs: [],
    showSuccess: false,
    successMessage: "Comisión generada correctamente",
    commissionNumber: 1,
  }),

  methods: {
    queryChanged(newQuery) {
      const query = {
        ...this.$route.query,
        ...newQuery,
      };

      try {
        this.$router.push({
          name: this.$route.name,
          query,
        });
        this.getPaymentData();
      } catch {
        this.getPaymentData();
      }
    },

    async getPaymentData() {
      this.loading = true;
      this.error = "";

      try {
        const query = this.$route.query;
        const response = await this.getRequest(
          `/commissions/newPaymentData`,
          true,
          query
        );
        this.loading = false;

        this.technician = response.technician;
        this.repairs = response.repairs;
        this.commissionNumber = response.commissionNumber;
      } catch (error) {
        console.error(error);
        this.loading = false;
        if (error.data) this.error = error.data.message;
        if (error.status >= 500) console.error(error);
      }
    },

    async submit(additionalFields) {
      this.loading = true;
      this.error = "";
      this.showSuccess = false;

      try {
        const body = { ...this.$route.query, ...additionalFields };
        await this.postRequest(`/commissions/new`, body);
        this.loading = false;

        this.showSuccess = true;
        this.reset();
      } catch (error) {
        console.error(error);
        this.loading = false;
        if (error.data) this.error = error.data.message;
        if (error.status >= 500) console.error(error);
      }
    },

    reset() {
      this.$router.push({ name: this.$route.name, query: {} });
      this.repairs = [];
    },
  },
};
</script>
