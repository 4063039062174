<template>
  <div>
    <v-card outlined :loading="loadingLocal">
      <v-toolbar color="secondary" dense flat dark tile>
        <v-toolbar-title>
          <span>{{ title }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col v-if="error" cols="12">
            <v-alert dense type="error">{{ error }}</v-alert>
          </v-col>
          <v-col cols="12" md="4" lg="6">
            <v-select
              v-model="technician"
              :items="technicians"
              item-text="name"
              item-value="_id"
              label="Técnico"
              filled
              color="secondary"
              item-color="secondary"
              no-data-text="Sin técnicos registrados"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <v-menu>
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="since"
                  v-on="on"
                  v-bind="attrs"
                  filled
                  label="Desde"
                  color="secondary"
                  readonly
                ></v-text-field>
              </template>
              <v-card width="400">
                <v-date-picker
                  v-model="since"
                  color="secondary"
                  width="100%"
                  locale="es"
                ></v-date-picker>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <v-menu>
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="until"
                  v-on="on"
                  v-bind="attrs"
                  filled
                  label="Hasta"
                  color="secondary"
                  readonly
                ></v-text-field>
              </template>
              <v-card width="400">
                <v-date-picker
                  v-model="until"
                  color="secondary"
                  width="100%"
                  locale="es"
                ></v-date-picker>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              :loading="loadingLocal"
              @click="queryChanged()"
            >
              <v-icon small class="mr-2">mdi-magnify</v-icon>
              <span>Buscar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { DateTime } from "luxon";

import { mapGetters } from "vuex";
import serverRequestMixin from "@/mixins/serverRequest.mixin";

export default {
  mixins: [serverRequestMixin],

  props: {
    title: { type: String, default: "Filtros" },
    current: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      loadingLocal: this.loading,
      error: "",
      technicians: [],
      technician: null,
      since: null,
      until: null,
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  watch: {
    current() {
      this.setCurrent();
    },

    loading(value) {
      this.loadingLocal = value;
    },
  },

  async mounted() {
    this.setCurrent();
    await this.getTechnicians();
  },

  methods: {
    setCurrent() {
      this.technician = this.current.technician || null;
      this.since =
        this.current.since || DateTime.now().set({ day: 1 }).toISODate();
      this.until = this.current.until || DateTime.now().toISODate();
    },

    async getTechnicians() {
      this.loadingLocal = true;
      try {
        const response = await this.getRequest(
          `/users/byBusiness/${this.user.businessId}`,
          true,
          { role: 3 }
        );
        this.loadingLocal = false;

        this.technicians = response.users;
        this.technician = this.technicians[0]._id;
      } catch (error) {
        this.loadingLocal = false;
        this.getterError = error.data.message;
        if (error.status >= 500) console.error(error);
      }
    },

    queryChanged() {
      const emitData = {
        technician: this.technician,
        since: this.since,
        until: this.until,
      };

      this.$emit("queryChanged", emitData);
    },
  },
};
</script>
