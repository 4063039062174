<template>
  <v-card color="secondary" dark>
    <v-card-title>
      <span>!Nos alegra que quieras llevar tu taller a otro nivel!</span>
    </v-card-title>
    <v-card-text>
      <span
        >Para continuar, necesitamos algunos datos acerca de tu negocio. Esto
        con el fin de mostrarlos en la nota que entregarás a los clientes cada
        que recibas un dispositivo.</span
      >
      <v-spacer></v-spacer>
      <span>Tus datos están seguros y no los compartimos con nadie.</span>
      <v-spacer></v-spacer>
      <span
        >Después de guardar estos datos podrás empezar a trabajar en la sección
        que desees.</span
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "hasNoBusiness",
};
</script>
