<template>
  <div>
    <v-card tile flat>
      <v-card-title>
        <span>Pago No. {{ commissionNumber }}</span>
        <v-btn
          color="green darken-2"
          large
          outlined
          block
          @click="$emit('submit', { payrollAmount })"
        >
          <v-icon class="mr-2">mdi-arrow-up</v-icon>
          <span>Pagar {{ currencyFormat(totalAmount) }}</span>
          <v-icon class="ml-2">mdi-arrow-up</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="4">
            <v-card color="accent" outlined>
              <v-card-title class="secondary--text">
                {{ repairs.length }} Servicios
              </v-card-title>
              <v-card-subtitle
                >Entregados en el rango seleccionado</v-card-subtitle
              >
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="accent" outlined>
              <v-card-title class="secondary--text">
                {{ currencyFormat(commissionsTotal) }}
              </v-card-title>
              <v-card-subtitle>En total de comisiones</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card flat>
              <v-text-field
                v-model="payrollAmount"
                filled
                color="secondary"
                label="Nómina (opcional)"
                type="number"
                :hint="
                  payrollAmount
                    ? currencyFormat(payrollAmount)
                    : 'La cantidad ingresada aquí se agregará al registro de nóminas'
                "
                persistent-hint
                prepend-inner-icon="mdi-currency-usd"
              ></v-text-field>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card-title>Lista de servicios</v-card-title>
            <v-data-table
              :headers="headers"
              :items="repairs"
              item-key="_id"
              dense
              disable-filtering
              disable-pagination
              disable-sort
              hide-default-footer
              no-data-text="Sin servicios"
            >
              <template v-slot:[`item.device.trademark`]="{ item }">
                <span>
                  {{ item.device.trademark }} {{ item.device.model }}
                </span>
              </template>
              <template v-slot:[`item.admissionDate`]="{ item }">
                <span>
                  {{ getShortDate(item.admissionDate) }}
                </span>
              </template>
              <template v-slot:[`item.lastStatusUpdate`]="{ item }">
                <span>
                  {{ getShortDate(item.lastStatusUpdate) }}
                </span>
              </template>
              <template v-slot:[`item.payment.totalCost`]="{ item }">
                <span>
                  {{ currencyFormat(item.payment.totalCost) }}
                </span>
              </template>
              <template v-slot:[`item.commission.commissionPercent`]="{ item }">
                <div>
                  <v-chip
                    label
                    :color="
                      item.commission.commissionPercent ? 'secondary' : null
                    "
                  >
                    <span v-if="item.commission.commissionPercent"
                      >{{ item.commission.commissionPercent }} %</span
                    >
                    <span v-else>N/A</span>
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.payment.prePayment`]="{ item }">
                <span>
                  {{ currencyFormat(getCommissionAmount(item)) }}
                </span>
              </template>
              <template v-slot:[`item.customer.name`]="{ item }">
                <span>
                  {{
                    item.customer.existingCustomer
                      ? item.customer.existingCustomer.name
                      : item.customer.name
                  }}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getShortDate } from "../../helpers/date.helper";
import { currencyFormat } from "../../helpers/numbers.helper";

export default {
  props: {
    repairs: { type: Array, default: () => [] },
    commissionNumber: { type: [Number, String], default: 1 },
    commissionPercent: { type: [Number, String], default: 0 },
  },

  data: () => ({
    headers: [
      {
        text: "Folio",
        value: "invoiceId",
      },
      {
        text: "Estado",
        value: "status.title",
      },
      {
        text: "Dispositivo",
        value: "device.trademark",
      },
      {
        text: "Cliente",
        value: "customer.name",
      },
      {
        text: "Fecha de ingreso",
        value: "admissionDate",
      },
      {
        text: "Modificado",
        value: "lastStatusUpdate",
      },
      {
        text: "Costo total",
        value: "payment.totalCost",
      },
      {
        text: "Comisión fija",
        value: "commission.commissionPercent",
      },
      {
        text: "Monto de comisión",
        value: "payment.prePayment",
      },
    ],

    payrollAmount: 0,
  }),

  computed: {
    commissionsTotal() {
      let result = 0;

      this.repairs.forEach((repair) => {
        result += this.getCommissionAmount(repair);
      });

      return result;
    },

    getCommissionAmount() {
      return function (repair) {
        if (repair.commission?.commissionPercent)
          return (
            repair.payment.totalCost *
            (repair.commission.commissionPercent / 100)
          );
        return repair.payment.totalCost * (this.commissionPercent / 100);
      };
    },

    totalAmount() {
      return Number(this.commissionsTotal) + Number(this.payrollAmount);
    },
  },

  methods: {
    getShortDate,
    currencyFormat,
  },
};
</script>
