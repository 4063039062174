<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-divider></v-divider>
        <v-subheader>Pago</v-subheader>
      </v-col>
      <v-col cols="12" md="6" v-if="paymentData.estimatedCost">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle
              >Costo estimado de la reparación</v-list-item-subtitle
            >
            <v-list-item-title>{{
              toCurrency(paymentData.estimatedCost)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6" v-if="paymentData.prePayment">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Adelanto del cliente</v-list-item-subtitle>
            <v-list-item-title>{{
              toCurrency(paymentData.prePayment)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Descuento aplicado</v-list-item-subtitle>
            <v-list-item-title>
              <v-chip small color="info"
                >{{ paymentData.discountPercent }}% =
                {{ toCurrency(discount) }}</v-chip
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Costo total</v-list-item-subtitle>
            <v-list-item-title>
              <v-chip small color="success">{{
                toCurrency(getTotalCost)
              }}</v-chip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { currencyFormat } from "@/helpers/numbers.helper";

export default {
  name: "paymentSection",

  props: {
    paymentData: { type: Object, required: true },
  },

  computed: {
    discount() {
      if (this.paymentData.hasDiscount && this.paymentData.estimatedCost) {
        const discountAmount =
          Number(this.paymentData.estimatedCost) *
          (Number(this.paymentData.discountPercent) / 100);
        return discountAmount;
      }
      return 0;
    },

    getTotalCost() {
      if (this.paymentData.totalCost) return this.paymentData.totalCost;
      if (this.paymentData.estimatedCost) return this.paymentData.estimatedCost;
      return 0;
    },
  },

  methods: {
    toCurrency(number) {
      return currencyFormat(number);
    },
  },
};
</script>
