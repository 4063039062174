<template>
  <div style="height: 100%">
    <v-card style="height: 100%">
      <v-toolbar color="secondary" tile dense flat dark>
        <v-toolbar-title>
          <span>Detalles del usuario</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="4" lg="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Nombre completo</v-list-item-subtitle>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="4" lg="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Sucursal</v-list-item-subtitle>
                <v-list-item-title>{{
                  user.branchOffice.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="4" lg="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle
                  >Porcentaje de comisión</v-list-item-subtitle
                >
                <v-list-item-title>
                  <v-chip label color="accent" class="secondary--text">
                    {{ user.commissionPercent || 0 }} %</v-chip
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    user: { Object, required: true },
  },
};
</script>
