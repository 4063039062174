var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","tile":"","loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"secondary","dense":"","flat":"","dark":"","tile":""}},[_c('v-toolbar-title',[_c('span',[_vm._v("Comisiones recientes")])])],1),_c('v-card-text',[_c('commissions-filter',{attrs:{"currentFilters":_vm.currentFilters},on:{"filtersUpdated":_vm.filtersUpdated}}),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.commissions,"item-key":"_id","loading":_vm.loading,"hide-default-footer":"","disable-filtering":"","disable-pagination":"","disable-sort":"","loading-text":"Cargando","no-data-text":"Sin comisiones","no-results-text":"Sin comisiones"},scopedSlots:_vm._u([{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getShortDate(item.paymentDate)))])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getShortDate(item.period.since))+" - "+_vm._s(_vm.getShortDate(item.period.until)))])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormat(item.totalAmount)))])]}},{key:"item.payroll",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.payroll ? _vm.currencyFormat(item.payroll.totalAmount) : _vm.currencyFormat(0)))])]}},{key:"item.commissionTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.payroll ? _vm.currencyFormat(item.totalAmount + item.payroll.totalAmount) : _vm.currencyFormat(item.totalAmount)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.getCommissionFile(item._id)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-printer")])],1),_c('v-list-item-content',[_c('span',[_vm._v("Obtener comprobante")])])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openRevertDialog(item)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1),_c('v-list-item-content',[_c('span',[_vm._v("Revertir comisión")])])],1)],1)],1)]}}],null,true)})],1)],1),(_vm.showRevert)?_c('revert-dialog',{attrs:{"show":_vm.showRevert,"commissionId":_vm.commissionSelected},on:{"cancel":function($event){return _vm.closeRevertDialog()},"commissionDeleted":function($event){return _vm.commissionDeleted()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }