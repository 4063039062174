<template>
  <div>
    <v-card flat tile :loading="loading">
      <v-toolbar color="secondary" dense flat dark tile>
        <v-toolbar-title>
          <span>Comisiones recientes</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <commissions-filter
          :currentFilters="currentFilters"
          @filtersUpdated="filtersUpdated"
        ></commissions-filter>

        <v-data-table
          :headers="tableHeaders"
          :items="commissions"
          item-key="_id"
          :loading="loading"
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          loading-text="Cargando"
          no-data-text="Sin comisiones"
          no-results-text="Sin comisiones"
        >
          <template v-slot:[`item.paymentDate`]="{ item }">
            <span> {{ getShortDate(item.paymentDate) }}</span>
          </template>
          <template v-slot:[`item.period`]="{ item }">
            <span>
              {{ getShortDate(item.period.since) }} -
              {{ getShortDate(item.period.until) }}</span
            >
          </template>
          <template v-slot:[`item.totalAmount`]="{ item }">
            <span> {{ currencyFormat(item.totalAmount) }}</span>
          </template>
          <template v-slot:[`item.payroll`]="{ item }">
            <span>
              {{
                item.payroll
                  ? currencyFormat(item.payroll.totalAmount)
                  : currencyFormat(0)
              }}</span
            >
          </template>
          <template v-slot:[`item.commissionTotal`]="{ item }">
            <span>
              {{
                item.payroll
                  ? currencyFormat(item.totalAmount + item.payroll.totalAmount)
                  : currencyFormat(item.totalAmount)
              }}</span
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu left>
              <template #activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="getCommissionFile(item._id)">
                  <v-list-item-icon class="mr-1">
                    <v-icon small>mdi-printer</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <span>Obtener comprobante</span>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="openRevertDialog(item)">
                  <v-list-item-icon class="mr-1">
                    <v-icon small>mdi-refresh</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <span>Revertir comisión</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <revert-dialog
      v-if="showRevert"
      :show="showRevert"
      :commissionId="commissionSelected"
      @cancel="closeRevertDialog()"
      @commissionDeleted="commissionDeleted()"
    ></revert-dialog>
  </div>
</template>

<script>
import serverRequestMixin from "@/mixins/serverRequest.mixin";
import { currencyFormat } from "@/helpers/numbers.helper";
import { getShortDate } from "@/helpers/date.helper";
import { DateTime } from "luxon";

import commissionsFilter from "./commissionsFilter.vue";

export default {
  mixins: [serverRequestMixin],

  components: {
    "revert-dialog": () => import("./revertCommission.dialog.vue"),
    "commissions-filter": commissionsFilter,
  },

  data: () => ({
    loading: false,
    error: "",
    commissions: [],
    tableHeaders: [
      {
        text: "No",
        value: "commissionNumber",
      },
      {
        text: "Técnico",
        value: "user.name",
      },
      {
        text: "Fecha de pago",
        value: "paymentDate",
      },
      {
        text: "Periodo",
        value: "period",
      },
      {
        text: "Monto de comisiones",
        value: "totalAmount",
      },
      {
        text: "Extra",
        value: "payroll",
      },
      {
        text: "Total pagado",
        value: "commissionTotal",
      },
      {
        text: "",
        value: "actions",
      },
    ],
    showRevert: false,
    commissionSelected: null,
    currentFilters: null,
  }),

  mounted() {
    this.getCommissions();
  },

  methods: {
    currencyFormat,
    getShortDate,

    async getCommissions(filters = null) {
      this.loading = true;
      this.error = "";

      try {
        const serverResponse = await this.getRequest(
          "/commissions",
          true,
          filters
        );
        this.loading = false;

        this.commissions = serverResponse.commissions;
        this.currentFilters = {
          since: DateTime.fromISO(serverResponse.since).toFormat("yyyy-LL-dd"),
          until: DateTime.fromISO(serverResponse.until).toFormat("yyyy-LL-dd"),
          useDatesFor: serverResponse.useDatesFor,
        };
      } catch (error) {
        this.loading = false;
        if (error.data) this.error = error.data.message;
        else this.error = "Error inesperado, favor de contactar con soporte";
      }
    },

    async getCommissionFile(commissionId) {
      this.loading = true;
      this.error = "";

      try {
        const url = `/commissions/${commissionId}?isFile=true`;
        const serverResponse = await this.getFileRequest(url);

        const file = new Blob([serverResponse.file], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.data) this.error = error.data.message;
        else this.error = "Error inesperado, favor de contactar con soporte";
      }
    },

    openRevertDialog(commission) {
      this.commissionSelected = commission._id;
      this.showRevert = true;
    },

    closeRevertDialog() {
      this.showRevert = false;
      this.commissionSelected = null;
    },

    commissionDeleted() {
      this.commissions = this.commissions.filter(
        (e) => e._id !== this.commissionSelected
      );
      this.closeRevertDialog();
    },

    filtersUpdated(filters) {
      this.getCommissions(filters);
    },
  },
};
</script>
