<template>
  <v-dialog v-model="show" persistent width="400">
    <v-card>
      <v-card-title>
        <span>Siguiente folio</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col v-if="error" cols="12">
            <v-alert type="error" dense>{{ error }}</v-alert>
          </v-col>
          <v-col v-else cols="12">
            <v-alert type="info" dense text>
              <span
                >El número que ingreses aquí será el próximo a utilizar. Se
                actualizará cada que se suba un nuevo servicio, sin embargo,
                puedes volver a modificarlo.
              </span>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="next"
              outlined
              dense
              color="secondary"
              label="Nuevo folio"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" block :loading="loading" @click="submit()">
              <v-icon small class="mr-2">mdi-content-save</v-icon>
              <span>Guardar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import serverRequestMixin from "@/mixins/serverRequest.mixin.js";

export default {
  mixins: [serverRequestMixin],

  props: {
    show: { type: Boolean, default: false },
    invoiceId: { type: String, default: null },
  },

  data() {
    return {
      next: this.invoiceId,
      loading: false,
      error: "",
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      this.error = "";

      try {
        const body = {
          ...(this.next && { next: this.next }),
        };

        const serverResponse = await this.putRequest(
          "/business/nextInvoiceId",
          body
        );
        this.loading = false;

        this.$emit("invoiceIdChanged", serverResponse.business.nextInvoiceId);
      } catch (error) {
        this.loading = false;
        if (error.data) this.error = error.data.message;
        else {
          this.error = "Error inesperado, por favor, contacta con soporte";
          console.error(error);
        }
      }
    },
  },
};
</script>
