<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="4">
        <v-menu>
          <template #activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              label="Desde"
              v-model="since"
              readonly
              filled
              dense
              color="secondary"
            ></v-text-field>
          </template>
          <v-card>
            <v-date-picker
              v-model="since"
              color="primary"
              locale="es"
              @change="updateFilters()"
            ></v-date-picker>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4">
        <v-menu>
          <template #activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              label="Hasta"
              v-model="until"
              readonly
              filled
              dense
              color="secondary"
            ></v-text-field>
          </template>
          <v-card>
            <v-date-picker
              v-model="until"
              color="primary"
              locale="es"
              @change="updateFilters()"
            ></v-date-picker>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="useDatesFor"
          label="Usar fechas para"
          dense
          filled
          :items="useDatesForItems"
          item-value="value"
          item-text="text"
          color="secondary"
          item-color="secondary"
          @change="updateFilters()"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  props: {
    currentFilters: {
      type: Object,
      default: () => null,
    },
  },

  data: () => ({
    since: "",
    until: "",
    useDatesFor: "paymentDate",
    useDatesForItems: [
      {
        text: "Fecha de pago",
        value: "paymentDate",
      },
      {
        text: "Rango pagado",
        value: "repairsRange",
      },
    ],
  }),

  watch: {
    currentFilters(v) {
      if (v) {
        this.setCurrentFilters();
      }
    },
  },

  methods: {
    setCurrentFilters() {
      this.since =
        this.currentFilters && this.currentFilters.since
          ? this.currentFilters.since
          : DateTime.now();
      this.until =
        this.currentFilters && this.currentFilters.until
          ? this.currentFilters.until
          : DateTime.now();
      this.useDatesFor =
        this.currentFilters && this.currentFilters.useDatesFor
          ? this.currentFilters.useDatesFor
          : this.useDatesForItems[0].value;
    },

    updateFilters() {
      this.$emit("filtersUpdated", {
        since: this.since,
        until: this.until,
        useDatesFor: this.useDatesFor,
      });
    },
  },
};
</script>
